@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root  {
        --background: 19 100% 95%;
        --foreground: 19 5% 0%;
        --card: 19 50% 90%;
        --card-foreground: 19 5% 10%;
        --popover: 19 100% 95%;
        --popover-foreground: 19 100% 0%;
        --primary: 19 100% 50%;
        --primary-foreground: 0 0% 100%;
        --secondary: 19 30% 70%;
        --secondary-foreground: 0 0% 0%;
        --muted: -19 30% 85%;
        --muted-foreground: 19 5% 35%;
        --accent: -19 30% 80%;
        --accent-foreground: 19 5% 10%;
        --destructive: 0 100% 30%;
        --destructive-foreground: 19 5% 90%;
        --border: 19 30% 50%;
        --input: 19 30% 18%;
        --ring: 19 100% 50%;
        --radius: 0.5rem;
        --sidebar-background: 0 0% 98%;
        --sidebar-foreground: 240 5.3% 26.1%;
        --sidebar-primary: 240 5.9% 10%;
        --sidebar-primary-foreground: 0 0% 98%;
        --sidebar-accent: 240 4.8% 95.9%;
        --sidebar-accent-foreground: 240 5.9% 10%;
        --sidebar-border: 220 13% 91%;
        --sidebar-ring: 217.2 91.2% 59.8%;
        --chart-1: 134 50% 54%;
        --chart-2: 284 50% 54%;
        --chart-3: 344 50% 54%;
        --chart-4: 104 50% 54%;
        --chart-5: 164 50% 54%;
        --chart-6: 277 80% 68%;
        --chart-7: 67 80% 68%;
        --chart-8: 127 80% 68%;
        --chart-9: 247 80% 68%;
        --chart-10: 307 80% 68%;
    }
    .dark  {
        --background: 19 50% 5%;
        --foreground: 19 5% 90%;
        --card: 19 50% 0%;
        --card-foreground: 19 5% 90%;
        --popover: 19 50% 5%;
        --popover-foreground: 19 5% 90%;
        --primary: 19 100% 50%;
        --primary-foreground: 0 0% 100%;
        --secondary: 19 30% 10%;
        --secondary-foreground: 0 0% 100%;
        --muted: -19 30% 15%;
        --muted-foreground: 19 5% 60%;
        --accent: -19 30% 15%;
        --accent-foreground: 19 5% 90%;
        --destructive: 0 100% 30%;
        --destructive-foreground: 19 5% 90%;
        --border: 19 30% 18%;
        --input: 19 30% 18%;
        --ring: 19 100% 50%;
        --radius: 0.5rem;
        --sidebar-background: 240 5.9% 10%;
        --sidebar-foreground: 240 4.8% 95.9%;
        --sidebar-primary: 224.3 76.3% 48%;
        --sidebar-primary-foreground: 0 0% 100%;
        --sidebar-accent: 240 3.7% 15.9%;
        --sidebar-accent-foreground: 240 4.8% 95.9%;
        --sidebar-border: 240 3.7% 15.9%;
        --sidebar-ring: 217.2 91.2% 59.8%;
        --chart-1: 134 50% 54%;
        --chart-2: 284 50% 54%;
        --chart-3: 344 50% 54%;
        --chart-4: 104 50% 54%;
        --chart-5: 164 50% 54%;
        --chart-6: 277 80% 68%;
        --chart-7: 67 80% 68%;
        --chart-8: 127 80% 68%;
        --chart-9: 247 80% 68%;
        --chart-10: 307 80% 68%;
    }
}

@layer base {
    * {
        @apply border-border;
    }

    html {
        font-family: Inter, ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    }

    body {
        @apply bg-background text-foreground;
    }
}